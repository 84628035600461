import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Start Here"
        description="My 10 second Bio and favorite things"
      />
      <div className="max-w-2xl mx-auto">
        <div>
          <h2>Hi there!</h2>
          <p>Thanks for visiting my internet home.</p>
          <p>
            My website is a collection of all my favorite things. You'll find my
            writting, opinions and favorite resources.
          </p>
          <p>On this page you'll learn a little bit about me.</p>
          <hr className="" />
          <h2>10 Second Bio</h2>
          <p>Born in Venezuela</p>
          <p>Montreal resident</p>
          <p>
            Work for <a href="https://shopify.com">Shopify</a>
          </p>
          <p>
            Creator of <a href="https://appdelante.com">Appdelante</a> an online
            school to learn software development in Spanish
          </p>
          <p>
            Skated for{" "}
            <a href="https://youtu.be/t4wD-3-GYCk?t=88">Original Skateboards</a>
          </p>
          <p>Favorite books: The War of Art, Mastery</p>
          <p>Favorite writer: Nassim Taleb</p>
          <hr className="" />
          <h2>Connect with me</h2>
          <p>
            I'm most active on{" "}
            <a href="https://twitter.com/ricardomacario">Twitter</a>. If you'd
            like to reach me, send me a direct message.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
